@import '../../../scss/common';

.user-data {
  margin-top: 15px;
  margin-bottom: 15px;
}

.org-info,.user-info {

  .col-xs-4, .col-xs-3 {
    width: $grid-3col;
    margin-right: $grid-gutter-width;
    .data {
      color: rgb(87, 176, 199);
    }
  }

  h1 {
    color: rgb(87, 176, 199);
  }
}
