.change-password-form form {
  margin-top: 20px;
  width: 400px;
}

.input-field {
  margin-bottom: 20px;

  .text-input * {
    // width: 100%;
  }

  input {
    padding: 10px 5px;
    font-size: 10px;
    font-weight: bold;
  }
}

.btn {
  margin-top: 15px;
  color: white;
  background-color: rgb(22, 59, 75);
  padding-top: 10px;
  padding-bottom: 10px;
  width: 100%;
}

.btn:hover {
  color: white;
}

.form {
  margin-top: px;
}
