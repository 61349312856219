@import '../../../scss/common';

@media all and (min-width: 480px) {
  .Login {
    padding: 60px 0;
    text-align: center;

    .error-message {
      max-width: 460px;
      background: rgba(255, 255, 255, 0.8) url('/images/error.png') no-repeat top 20px left 15px;
      margin: 0 auto;
      padding: 20px 20px 20px 45px;
      font-size: 16px;
      text-align: left;
      color: $errorcolor;
    }
    .message {
      font-size: 22px;
      font-weight: bold;
      color: green;
    }
  }

  .Login form {
    margin: 35px auto;
    width: $grid-4col;

    .form-row {
      text-align: left;
    }
  }

  .LoginTitle {
    text-align: center;
    font-weight: bold;
  }

  .LoginBtn {
    color: white;
    background: #293852;
  }

  .Logo {
    max-height: 150px;
    max-width: 460px;
  }

  .helpText {
    max-width: 460px;
    text-align: center;
    margin: 0 auto;
    margin-top: -20px;
    background: white;
    opacity: 0.7;
    padding: 15px;
  }

  .disclaimer {
    left: 0px;
    padding: 10px;
    font-size: 11px;
    font-weight: bold;
    max-width: 100%;
    background: white;
    opacity: 0.7;
    position: fixed;
    bottom: 0;
  }
}
