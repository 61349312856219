@import '../../../scss/common';

.active-table {
  thead {
    th {
      padding: 0 40px 0 5px;
    }
    th.actionable {
      padding: 0 0 0 5px !important;
    }
    
    button.sort {
      background: none;
      border: 0;
      color: black;
      &.active {
        color: $anotherblue;
      }
      &.asc span:before {
        content: '\25b2';
      }
      &.desc span:before {
        content: '\25bc';
      }
    }
  }

  .search-bar {
    width: $grid-3col;
    margin-bottom: 30px;
    margin-top: 30px;
    input {
      background-color: white;
      background-image: url('/images/search.png');
      background-position: center right 10px;
      background-repeat: no-repeat;
    }
  }

  .col-status {
    white-space: nowrap;
  }
}

.pagination-wrapper {
  text-align: center;
  .pagination {
    display: inline-block;
    .page-item {
      padding: 7px;
      button {
        border: 0;
        background: 0;
        font-size: 14pt;
        color: gray;

        &:hover {
          color: $anotherblue;
        }
      }

      &.active button {
        color: $blue;
        font-weight: bold;
      }

      &.first,
      &.last {
        display: none;
      }
    }
  }
}
