@import '../../../scss/common';

html,
body,
#root {
  height: 100%;
}
#root {
  height: 100%;
  margin: 0;
  padding: 0;
}

// collapsed sidebar (icons)
.App.container.logged-in.collapsed {
  // margin-left: $grid-1col + $grid-gutter-width + $grid-gutter-half-width;
  .nav-bar {
    margin-left: 0;
    width: $grid-1col + $grid-gutter-half-width;
    ul li a,
    ul li.active a {
      text-indent: -99999px;
      overflow: none;
    }
  }
  .content {
    width: $grid-10col;
    margin-left: $grid-gutter-half-width;
    .content-inner {
      margin-bottom: 50px;
    }
  }
}

// pulled out sidebar (icons+text)
.App.container.logged-in.pulled-out {
  // margin-left: $grid-2col-spacer + $grid-gutter-half-width;
  .nav-bar {
    width: $grid-2col + $grid-gutter-half-width;
    a {
      color: black;
    }
  }

  .content {
    width: $grid-10col;
    margin-left: $grid-1col-spacer + $grid-gutter-half-width;
  }
}

.App.container.logged-in {
  min-height: 100%;
  box-sizing: border-box;
  padding-left: 0;
  margin-left: $grid-1col-spacer;
  // transition: margin 0.1s;

  .content {
    padding-top: 165px;
    background: url('/images/logo-header.png') top 65px left 0px no-repeat;

    .content-inner {
      float: left;
      width: 100%;
    }
  }

  form .row {
    margin-left: 0;
    margin-right: 0;
  }

  .nav {
    width: 100%;

    img {
      width: 100%;
    }
  }

  .nav-bar {
    // transition: width 0.1s;
    height: 100vh;
    top: 0;
    left: 0;
    position: fixed;

    .nav-bar-inner {
      height: 100vh;
    }

    .col-xs-3 {
      height: 100%;
      width: 100%;
      padding: 0px;
    }

    // override bootstrap's spacing between
    ul.nav.nav-pills li + li {
      margin-top: 0;
    }

    ul {
      height: 100%;
      background-color: rgba(230, 228, 223, 0.781);
      margin-left: 0;
      padding-top: 150px;

      li a {
      }

      li a {
        margin-top: 0 !important;
        margin-left: 5px;
        border-radius: 0;
        // color: transparent;
        padding-left: 75px;
        padding-top: 20px;
        padding-bottom: 20px;
        &:hover {
          background-color: #aaa;
        }
        background: url('/images/nav-users.png') 20px center no-repeat;
      }
      li:hover {
        background-color: #aaa;
      }
      li {
        border-left: 5px solid rgba(0, 0, 0, 0);
      }
      li.active {
        background-color: white;
        &:hover {
          background-color: #aaa;
        }
        border-left: 5px solid $blue;
        a {
          color: black;
        }
      }

      @each $navitem in containers, container-history, users, notifications, analytics,
        organizations, settings, signout
      {
        li.#{$navitem} a {
          background-image: url('/images/nav-#{$navitem}.png');
        }
      }

      li.signout {
        margin-top: 75px !important;
      }
    }

    hr {
      border-color: black;
    }
  }

  .breadcrumb {
    padding: 0px;
    background-color: white;
  }

  .error-message {
    background: rgba(255, 255, 255, 0.8) url('/images/error.png') no-repeat top 20px left 15px;
    margin: 0 auto;
    padding: 20px 20px 20px 45px;
    font-size: 16px;
    text-align: left;
    color: $errorcolor;
  }

  .org-list-header {
    height: 50px;
    width: 100%;

    .col-left {
      height: 100%;
      float: left;
    }

    .col-right {
      height: 100%;
      float: right;

      .btn {
        margin: 0;
      }
    }
  }

  .user-list-header {
    height: 50px;
    width: 100%;

    .col-left {
      height: 100%;
      float: left;
    }

    .col-right {
      height: 100%;
      float: right;

      .btn {
        margin: 0;
      }
    }
  }

  table {
    width: 100%;

    .edit-user {
      left: 50px;
    }

    tr {
      padding-bottom: 50px;
      margin: 50px;
    }

    th {
      width: 20%;
    }

    th,
    td {
      padding-left: 5px;
    }

    .dropdown {
      float: right;
      width: 28px;
      height: 28px;
      position: relative;
      display: inline-block;
      background: url('/images/options-white.png');

      a {
        text-decoration: none;
      }

      a:hover {
        text-decoration: underline;
      }

      button {
        border: 0px;
        background: #f9f9f9;
        color: $bluefixed;
        padding: 0px;
      }

      button:hover {
        text-decoration: underline;
      }
    }

    .dropdown-content {
      white-space: nowrap;
      margin-right: -20px;
      top: 28px;
      right: 0;
      display: none;
      position: absolute;
      background-color: #f9f9f9;
      box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
      padding: 12px 16px;
      width: $grid-2col;
      z-index: 1;
    }

    .dropdown:hover {
      background: url('/images/options.png');
    }

    .dropdown:hover .dropdown-content {
      display: block;
    }
  }

  .css-vj8t7z {
    border-radius: 0px;
    border-color: hsl(0, 0%, 66%);
  }

  .css-2o5izw {
    border-radius: 0px;
  }

  .profile-data {
    margin-bottom: 20px;
  }
}

.App.container.notifications-unread li.notifications a {
  position: relative;
  &:before {
    content: "";
    width: 10px;
    height: 10px;
    border-radius: 50%;
    left: 45px;
    top: 20px;
    background: red;
    position: absolute;
  }
}
