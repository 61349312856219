@import '../../../scss/common';

.container-list {
  table {
    th {
      white-space: nowrap;
    }
    .col-status {
      white-space: nowrap;
    }
  }
}
