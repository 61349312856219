@import '../../../scss/common';

.user-settings {
  .notifications {
    margin-top: 50px;
  }

  .notification-settings {
    li {
      border: 0;
      margin: 0;
      padding: 0;
      margin-bottom: 20px;

      &:after {
        content: '';
        clear: both;
      }

      p {
        margin: 0;
        padding: 0;
        width: 20%;
      }

      div {
        width: 80%;
        float: right;
      }

      &.active {
      }

      button {
      }
    }
  }
}

button.toggle {
  background: #ccc;
  opacity: 0.5;
  width: 60px;
  background: white;
  position: relative;
  font-smooth: always;
  text-transform: uppercase;
  padding: 5px;
  &:after {
    content: " ";
    top: 5px;
    position: absolute;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 1px solid;
  }
  &.on {
    border: 1px solid $toggle-active;
    border-radius: 5px;
    text-align: left;
    color: $toggle-active;
    opacity: 1;
    &:after {
      right: 5px;
      border: 1px solid $toggle-active;
      background: $toggle-active;
    }
  }
  &.off {
    text-align: right;
    border: 1px solid $toggle-inactive;
    background: $toggle-inactive;
    color: white;
    border-radius: 5px;
    opacity: 1;
    &:after {
      left: 5px;
      border: 1px solid $toggle-inactive;
      background: white;
    }
  }

  &:focus {
    outline: none;
  }
}
