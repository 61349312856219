@import '../../../scss/common';

$notification-color-unread: #b70000;
$notification-color-read: #000000;

.notifications {
  .txt {
    margin: 0;
    padding: 0;
    border: 0;
    background: none;
    float: right;
    text-decoration: underline;
    color: $bluefixed;
    &:hover {
      color: $blue;
    }
  }

  ul {
    list-style-type: none;
    margin: 0;
    padding: 0;

    li {
      margin: 20px 0;
      padding: 0;
      padding-left: 25px;
      position: relative;
      margin-bottom: 20px;
      padding-bottom: 20px;
      border-bottom: 1px solid #ccc;

      h3 button {
        border: 0;
        background: none;
        margin: 0;
        padding: 0;
      }

      button.mark {
        position: absolute;
        top: 3px;
        left: 0;
        width: 15px;
        height: 15px;
        border-radius: 50%;

        &.unread {
          background: $notification-color-read;
          text-indent: -9999em;
        }
        &.read {
          background: $notification-color-unread;
          text-indent: -9999em;
        }
      }

      button.dismiss {
        float: right;
        border: 0;
        margin: 0;
        padding: 0;
      }

      .date-time {
        color: #ccc;
      }

      .action-required {
        color: $notification-color-read;
      }
      &.unread .action-required {
        color: $notification-color-unread;
      }
    }
  }
}
