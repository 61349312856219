@import '../../scss/common';

$statusbar-line-color-active: $anotherblue;
$statusbar-line-color-disabled: #d8d8d8;
$statusbar-text-color-disabled: #9b9b9b;

$node-width: 110px;
$circle-size: 30px;

// note "progress-bar" is taken by bootstrap already!
.progress-node-bar {
  display: table;
  width: 600px;
  margin-bottom: 25px;
  table-layout: fixed;

  .node {
    // draw circles
    .circle {
      position: absolute;
      top: 0;
      left: ($node-width - $circle-size) / 2;
      width: $circle-size;
      height: $circle-size;
      border: 5px solid $statusbar-line-color-active;
      background: white;
      border-radius: 50%;
    }
    &.selected .circle {
      border-color: white;
      box-shadow: 0 2px 4px 0 $shadowcolor;
      background: $blue;
    }
    &.disabled .circle {
      background: $statusbar-line-color-disabled;
      border-color: $statusbar-line-color-disabled;
    }

    // node positioning
    position: relative;
    display: table-cell;
    width: $node-width;
    padding-top: 50px;
    text-align: center;

    // draw lines between nodes
    &:before,
    &:after {
      content: '';
      border: 1px solid $statusbar-line-color-active;
      position: absolute;
      width: 50%;
      height: 1px;
      top: 12px;
      z-index: -1;
    }
    &:before {
      left: 0;
    }
    &:after {
      right: 0;
    }

    // gray out lines for future nodes
    &.disabled:after,
    &.disabled:before,
    &.next-disabled:after {
      border-color: $statusbar-line-color-disabled;
    }

    // hide lines reaching past our nodes
    &.first:before,
    &.last:after {
      display: none;
    }

    // text styles
    a {
      text-decoration: underline;
      color: $anotherblue;
      font-size: 14px;
      &:focus {
        outline: none;
      }
    }
    &.selected a {
      font-weight: 600;
      color: $blue;
    }
    &.disabled a {
      color:$anotherblue;
      pointer-events: pointer;
      &:focus {
        text-decoration: underline;
      }
    }
  }
}
