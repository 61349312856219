@import '../scss/common';
// @import '../scss/debug';

@font-face {
  font-family: 'San Francisco';
  font-weight: 400;
  src: url('https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-regular-webfont.woff');
}

body {
  font-family: 'San Francisco';
}

.App {
  &.loading {
    opacity: 0.1;
    margin-top: 100px;
  }
  .loading {
    opacity: 0.3;
  }
}

$spacing_size: 25px;

a {
  color: $bluefixed;
  &:visited {
    color: $bluefixed;
  }
}

h1 {
  font-size: 28px;
  font-weight: bold;
  line-height: 34px;
  margin-top: 0;
  margin-bottom: $spacing_size;
  color: $blue;
}

h2 {
  font-size: 22px;
  font-weight: bold;
  line-height: 26px;
  margin-bottom: $spacing_size;
}

h3 {
  font-size: 18px;
  font-weight: 600;
}

td {
  padding: 15px;
}

// adding arbitrary specifity to deal with bootstrap
#root .container .content .btn {
  font-weight: 400;
  background: $blue;
  border-radius: 5px;
  border-width: 2px;
  color: white;
  &:visited {
    color: white;
  }

  &.secondary {
    background: white;
    color: $bluefixed;
    border-color: $anotherblue;
    &:visited {
      color: $blue;
    }
    &:hover {
      color: $blue;
      background: white;
      border-color: $blue;
    }
  }

  &:hover {
    color: white;
    background: $anotherblue;
    border-color: $anotherblue;
  }
}

form {
  label {
    font-size: 14px;
    font-weight: normal;
  }

  .form-row {
    width: $grid-4col;
  }

  input[type='text'],
  input[type='password'],
  select {
    font-size: 14px;
    font-weight: normal;
    border: 1px solid $bluefixed;
    padding: 5px;
    box-shadow: none;
    border-radius: 0;
    width: $grid-4col;
    &:focus {
      border: 2px solid $lightblue;
      outline: none;
      border-radius: 0;
      box-shadow: none;
    }
  }
  input::placeholder {
    color: $bluefixed;
  }

  .form-row {
    .input-field.errors input,
    select {
      border-color: $errorcolor;
      background: url('/images/error.png') no-repeat center right 10px;
    }

    .error-message {
      color: $errorcolor;
      font-size: 11px;
      background: none;
      &:before {
        content: '' !important;
      }
    }
  }

  // .select-input .select-input > div > div {
  //   border: 0;
  //   // overriding react-select css :(
  //   border-bottom: 2px solid black !important;
  //   border-radius: 0;
  //   span {
  //     background: none;
  //   }
  // }

  // .select-input .select-input > div > div > div {
  //   border: 0;
  //   // overriding react-select css :(
  //   border-bottom: 2px solid black !important;
  //   border-radius: 0;
  //   span {
  //     background: none;
  //   }
  // }
}

table {
  font-size: 15px;
  border: 0;

  thead {
    tr {
      border-bottom: 1px solid $bluefixed;
    }
  }

  tbody {
    tr {
      border-bottom: 1px solid $anotherblue;
      &:hover {
        background: $grayhover;
      }

      td a {
        text-decoration: underline;
      }
    }
    tr:nth-child(odd) {
      background: #eee;
    }
  }
}

.btn {
  margin-top: 10px;
  margin-right: $grid-gutter-width;
  width: $grid-2col;

  .btn-primary {
    margin-right: 0px;
  }

  &.cancel {
    background-color: white;
    border-color: $blue;
    color: $blue;
  }
}

.actions {
  float: right;
  ul.nav {
    text-align: right;
    li {
      display: inline;
      a.btn {
        width: $grid-2col;
        display: inline-block;
        margin: 0;
        margin-left: $grid-gutter-width;
      }
    }
  }
}

.bottom {
  margin-bottom: $spacing_size;
}