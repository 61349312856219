@import '../../../scss/common';

h1 {
  font-size: 30px;
  margin-bottom: 25px;
}

h2 {
  font-size: 23px;
  font-weight: bold;
  margin-bottom: 25px;
}

h3 {
  font-size: 15px;
  margin-bottom: 25px;
}

.container-view {
  .actions {
    .btn {
      margin: 0;
    }
  }

  h3 {
    span {
      text-decoration: none;
      border-bottom: 2px solid $bluefixed;
    }
  }
}

.container-status-bar {
  margin-top: 30px;
  margin-bottom: 10px;

  .status-bar {
    margin-top: 20px;
    margin-bottom: 20px;
  }

  img {
    height: 25px;
    width: 25px;
  }
}

.container-data {
  margin-bottom: 20px;
}

.container-information {
  br {
    height: 5px;
  }

  .col-xs-3 {
    width: $grid-2col;
    margin-right: $grid-gutter-width;
  }
}

.profile-label {
  font-size: 14px;
}

.profile-value {
  font-size: 16px;
  color: $bluefixed;
  .edited-value {
    color: $editedValue;
    position: relative;
    display: inline-block;
    cursor: pointer;
  }
}

.edited-value .tooltiptext {
  visibility: hidden;
  width: 250px;
  background-color: #eee;
  color: #333;
  text-align: center;
  border-radius: 6px;
  padding: 4px 0;
  position: absolute;
  z-index: 1;
  top: -5px;
  left: 110%;
  font-family: 'San Francisco';
  font-size: 12px;
}

.edited-value .tooltiptext::after {
  content: "";
  position: absolute;
  top: 50%;
  right: 100%;
  margin-top: -5px;
  border-width: 5px;
  border-style: solid;
  border-color: transparent #eee transparent transparent;
}

.edited-value:hover .tooltiptext {
  visibility: visible;
}


