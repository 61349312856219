// colors
$blue: #206779;
// looks better than $blue for small text
$bluefixed: #0384a5; 
$anotherblue: #32A3BF;
$lightblue: lightblue;
$errorcolor: #e19200;
$divider-light: #D6D6D6;
$divider-dark: #979797;
$grayhover: #f7f7f7;
$shadowcolor: #32A3BF;
//color for the edited fields
$editedValue: #e90707;

// config
$grid-columns: 12;
$grid-gutter-width: 30px;
$grid-gutter-half-width: $grid-gutter-width / 2;
$grid-page-width-min: 1280px;
$grid-page-width-max: 1920px;
$page-width: 1440px;

// generated grid vars
@function grid-size($cols) {
  $page-content-width: ($page-width - ($grid-gutter-width * $grid-columns));
  $column-width: $page-content-width / $grid-columns;

  $gutter-total: $cols * $grid-gutter-width;
  $column-size: $column-width * $cols;

  @return ($column-size) + ($grid-gutter-width * ($cols - 1));
}
$grid-1col: grid-size(1);
$grid-2col: grid-size(2);
$grid-3col: grid-size(3);
$grid-4col: grid-size(4);
$grid-5col: grid-size(5);
$grid-6col: grid-size(6);
$grid-7col: grid-size(7);
$grid-8col: grid-size(8);
$grid-9col: grid-size(9);
$grid-10col: grid-size(10);
$grid-11col: grid-size(11);
$grid-12col: grid-size(12);
$grid-1col-spacer: grid-size(1) + $grid-gutter-width;
$grid-2col-spacer: grid-size(2) + $grid-gutter-width;
$grid-3col-spacer: grid-size(3) + $grid-gutter-width;
$grid-4col-spacer: grid-size(4) + $grid-gutter-width;
$grid-5col-spacer: grid-size(5) + $grid-gutter-width;
$grid-6col-spacer: grid-size(6) + $grid-gutter-width;
$grid-7col-spacer: grid-size(7) + $grid-gutter-width;
$grid-8col-spacer: grid-size(8) + $grid-gutter-width;
$grid-9col-spacer: grid-size(9) + $grid-gutter-width;
$grid-10col-spacer: grid-size(10) + $grid-gutter-width;
$grid-11col-spacer: grid-size(11) + $grid-gutter-width;
$grid-12col-spacer: grid-size(12) + $grid-gutter-width;

$toggle-active: $anotherblue;
$toggle-inactive: $divider-light;
