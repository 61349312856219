@import '../../../scss/common';

.App.container.guest {
  min-height: 100%;
  min-width: 100%;
  max-width: 1920px;
  margin: 0 auto;

  &:before {
    content: '';
    z-index: -1;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    margin: auto;
    width: 100%;
    height: 100%;
    background: url('/images/bg-login.png') no-repeat center center fixed;
    background-size: cover;
    opacity: 0.5;
  }

  .row {
    margin: 0;
  }

  h1 {
    color: black;
  }

  .Login {
    .logo {
      width: $grid-4col;
      margin: 0 auto;
      text-align: left;
    }
    img.Logo {
      display: inline-block;
      text-align: left;
      margin-left: 2 * $grid-gutter-width;
    }

    h1 {
      margin-top: 50px;
    }

    button[type='submit'] {
      width: 100%;
      margin: 15px 0;
    }

    label {
      font-weight: bold;
    }
  }
}
