@import '../../../scss/common';

.accordion {
  .accordion-section {
    margin-bottom: 25px;
    &.disabled {
      color: #888;
    }


    .accordion-heading {
      height: 30px;
      border-bottom: 1px solid #ccc;
      button {
        border: 0;
        padding: 0;
        background: none;
        &[disabled] {
          cursor: not-allowed;
        }
      }
    }

    .accordion-body {
      font-size: 16px;
      padding: 20px 0;

      .col-xs-6 {
        width: $grid-3col;
        margin-right: $grid-gutter-width;
      }
    }
  }
}
