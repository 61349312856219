@import '../../../scss/common';

.panel-group {
  padding-top: 20px;

  .panel {
    background-color: white;
    box-shadow: none;

    .panel-title {
      font-size: 18px;
      font-weight: 600;
    }

    .panel-heading {
      height: 30px;
      padding: 0px;
      border-bottom: 1px solid #ccc;
      background-color: white;
    }

    .panel-body {
      border-top: 0;
      padding: 0;

      h3 {
        color: $bluefixed;
        margin-bottom: 20px;
      }
    }
  }

  .panel-default {
    margin-bottom: 25px;
    border: 0px;
  }
}
